/* Centering the 404 page */
.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    background-color: #fff0f5;
    padding: 20px;
  }
  
  /* Bunny image */
  .not-found-image {
    width: 250px;
    max-width: 100%;
    animation: float 3s infinite ease-in-out;
  }
  
  /* Floating animation */
  @keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  /* Title */
  .not-found-title {
    font-family: 'Comic Sans MS', cursive;
    font-size: 2rem;
    color: #ff69b4;
    margin: 20px 0;
  }
  
  /* Text */
  .not-found-text {
    font-size: 1.2rem;
    color: #333;
    max-width: 400px;
    line-height: 1.6;
  }
  
  /* Home button */
  .home-button {
    background: linear-gradient(45deg, #ff69b4, #ff1493);
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    text-decoration: none;
    margin-top: 20px;
    display: inline-block;
    box-shadow: 0px 4px 12px rgba(255, 105, 180, 0.3);
    transition: all 0.3s ease;
  }
  
  .home-button:hover {
    background: linear-gradient(45deg, #ff1493, #ff69b4);
    transform: translateY(-2px);
    box-shadow: 0px 6px 15px rgba(255, 105, 180, 0.5);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .not-found-title {
      font-size: 1.8rem;
    }
    .not-found-text {
      font-size: 1rem;
    }
  }
  