/* Footer Container */
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff0f5;
  padding: 20px;
  border-top: 2px solid #ffc0cb;
  box-shadow: 0 -2px 10px rgba(255, 182, 193, 0.4);
}

.footer-container {
  background-color: #f8f8f8;
}

.footer-link {
  color: #1a1a1a;
  font-weight: 500;
}

.social-icon {
  color: #1a1a1a;
}

.footer-copyright {
  color: #1a1a1a;
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}


/* Footer Links */
.footer-links,
.footer-policy-links {
  display: flex;
  gap: 15px;
  margin: 10px 0;
}

.footer-link {
  color: #ff69b4;
  font-family: 'Comic Sans MS', 'cursive';
  text-decoration: none;
  font-size: 1em;
  transition: color 0.2s ease-in-out;
}

.footer-link:hover {
  color: #db7093;
  text-decoration: underline;
}

/* Social Media Links */
.social-media {
  display: flex;
  gap: 15px;
  margin: 10px 0;
}

.social-icon {
  color: #ff69b4;
  font-size: 1.5em;
  transition: transform 0.2s ease, color 0.2s ease;
}

.social-icon:hover {
  color: #db7093;
  transform: scale(1.1);
}

/* Copyright */
.footer-copyright {
  color: #ff69b4;
  font-family: 'Comic Sans MS', 'cursive';
  font-size: 0.9em;
  margin-top: 15px;
}
