.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #fff0f5, #fff5f8);
  padding: 0 2rem;
  box-shadow: 0 2px 10px rgba(255, 182, 193, 0.2);
}

.navbar-logo {
  font-size: 2rem;
  font-weight: 700;
  color: #ff69b4;
  text-decoration: none;
  font-family: 'Dancing Script', cursive;
}

.navbar-center {
  display: flex;
  gap: 2.5rem;
}

.navbar-link {
  color: #ff69b4;
  text-decoration: none;
  font-family: 'Dancing Script', cursive;
  font-size: 1.2rem;
  transition: color 0.2s ease;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

/* Cart Styles */
/* Cart Styles */
.cart-dropdown {
  position: relative;
}

.navbar-cart {
  color: #ff69b4;
  text-decoration: none;
  font-family: 'Dancing Script', cursive;
  font-size: clamp(1rem, 2vw, 1.1rem);
  padding: 8px 12px;
  border-radius: 20px;
  transition: all 0.3s ease;
  background: rgba(255, 192, 203, 0.1);
  border: 2px dashed #ffb6c1;
}

.navbar-cart:hover {
  background: rgba(255, 105, 180, 0.2);
  transform: scale(1.05);
}

.cart-content {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(255, 182, 193, 0.3);
  min-width: 250px;
  border: 3px solid #ffd1dc;
  animation: dropDown 0.3s ease;
}

.cart-preview {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
}

.cart-count, .cart-total {
  color: #ff69b4;
  font-size: 1.1rem;
  padding: 10px;
  background-color: #fff5f8;
  border-radius: 12px;
  border: 2px dashed #ffb6c1;
  font-family: 'Dancing Script', cursive;
}

.view-cart-btn {
  display: block;
  margin-top: 15px;
  background: linear-gradient(45deg, #ff69b4, #ffb6c1);
  color: white;
  padding: 12px;
  border-radius: 25px;
  text-decoration: none;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.view-cart-btn:hover {
  transform: scale(1.05);
  background: linear-gradient(45deg, #ff1493, #ff69b4);
  box-shadow: 0 4px 10px rgba(255, 105, 180, 0.3);
}

@keyframes dropDown {
  from { 
    opacity: 0;
    transform: translateY(-10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}


/* User Dropdown Styles */
.avatar-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #ff69b4;
  object-fit: cover;
  transition: transform 0.2s ease;
}

.avatar:hover {
  transform: scale(1.05);
}

.user-menu {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  background: white;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(255, 182, 193, 0.3);
  min-width: 200px;
  z-index: 1000;
}

.user-link {
  display: block;
  padding: 12px 20px;
  color: #ff69b4;
  text-decoration: none;
  font-family: 'Dancing Script', cursive;
  font-size: 1.1rem;
  text-align: center;
  border-radius: 10px;
  transition: background-color 0.2s ease;
}

.user-link:hover {
  background-color: #fff5f8;
}

.logout-btn {
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0 1rem;
    height: 70px;
  }

  .avatar {
    width: 50px;
    height: 50px;
  }

  .cart-content,
  .user-menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
    padding: 2rem;
    animation: slideUp 0.3s ease;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0 0.8rem;
    height: 60px;
  }

  .avatar {
    width: 45px;
    height: 45px;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
