/* Loading Animation */
.loading-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #fff5f8, #ffeef5);
  }
  
  .loading-content {
    text-align: center;
  }
  
  .bunny-loader {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: relative;
    animation: bounce 1s infinite;
  }
  
  .bunny-ears {
    position: absolute;
    top: -30px;
    left: 15px;
    width: 70px;
    height: 40px;
    background: #ff69b4;
    border-radius: 50% 50% 0 0;
  }
  
  .bunny-face {
    width: 80px;
    height: 80px;
    background: #ff69b4;
    border-radius: 50%;
    position: relative;
  }
  
  .loading-text {
    color: #ff69b4;
    font-size: 1.5rem;
    margin-top: 2rem;
    font-family: 'Dancing Script', cursive;
  }
  
  .loading-sparkles {
    margin-top: 1rem;
    font-size: 2rem;
    animation: sparkle 1.5s infinite;
  }
  
  .loading-sparkles span {
    margin: 0 0.5rem;
    display: inline-block;
  }
  