.redirect-container {
    text-align: center;
    max-width: 600px;
    margin: 80px auto;
    padding: 30px;
    background: linear-gradient(135deg, #ffecf4, #ffe6f0);
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(255, 105, 180, 0.2);
  }
  
  .redirect-title {
    font-size: 2rem;
    font-family: "Dancing Script", cursive;
    color: #ff69b4;
    margin-bottom: 15px;
  }
  
  .redirect-text {
    font-size: 1.2rem;
    color: #ff8da1;
  }
  
  .loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    position: relative;
    height: 100px;
  }
  
  .bunny {
    width: 60px;
    height: 60px;
    background: url("./images/bunny.webp") no-repeat center center;
    background-size: contain;
    position: absolute;
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  .heart {
    width: 20px;
    height: 20px;
    background: url("./images/heart.webp") no-repeat center center;
    background-size: contain;
    position: absolute;
    animation: floatUp 3s infinite ease-in-out;
  }
  
  .heart:nth-child(2) {
    left: -50px;
    animation-delay: 0.5s;
  }
  
  .heart:nth-child(3) {
    right: -50px;
    animation-delay: 1s;
  }
  
  .heart:nth-child(4) {
    top: -40px;
    animation-delay: 1.5s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
  @keyframes floatUp {
    0% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateY(-50px) scale(1.2);
      opacity: 0;
    }
  }
  
  .redirect-note {
    font-size: 1rem;
    margin-top: 20px;
  }
  
  .redirect-note a {
    color: #ff69b4;
    text-decoration: none;
    font-weight: bold;
  }
  
  .redirect-note a:hover {
    text-decoration: underline;
  }
  